import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import validator from "validator";
import { uploadFile } from "react-s3";
import { v4 as uid4 } from "uuid";
import * as S from "./style";
import AttachButton from "../../AttachButton";
import CTA from "../../../components/CTA";
import LoadSpinner from "../../LoadSpinner";

export default function ApplicationSection({ id, data, awsConfig }) {
  const [inputValid, setInputValid] = useState(false);

  const [applying, setApplying] = useState(false);
  const [error, setError] = useState(false);
  const [applySuccess, setApplySuccess] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setInputValid(
      validator.isEmail(email) &&
        email.trim() &&
        firstName.trim() &&
        lastName.trim() &&
        isChecked &&
        file
    );
  }, [firstName, lastName, email, isChecked, file]);

  function apply() {
    if (firstName && lastName && email && isChecked) {
      setApplying(true);

      const _data = {
        email,
        firstname: firstName,
        lastname: lastName,
      };

      fetch("/.netlify/functions/createcandidate", {
        method: "POST",
        body: JSON.stringify(_data),
      }).then(async (res) => {
        const resData = await res.json();
        const _data2 = {
          candId: resData.data.id,
          jobId: id,
          letter: file,
        };

        fetch("/.netlify/functions/applyjob", {
          method: "POST",
          body: JSON.stringify(_data2),
        }).then(async (res2) => {
          if (file.current) {
            const filetype = file.current.name.split(".").pop();
            const newFilename =
              "CV-" +
              _data.firstname +
              "_" +
              _data.lastname +
              "-" +
              uid4().split("-").pop() +
              "." +
              filetype;
            const newFile = new File([file.current], newFilename, {
              type: file.current.type,
            });

            uploadFile(newFile, awsConfig)
              .then((uploadData) => {
                const _data3 = {
                  id: _data2.candId,
                  url: uploadData.location,
                  filename: uploadData.key,
                };

                fetch("/.netlify/functions/uploadfile", {
                  method: "POST",
                  body: JSON.stringify(_data3),
                }).then(async (res3) => {
                  setApplying(false);
                  setApplySuccess(true);

                  return true;
                });
              })
              .catch((err) => {
                console.error(err);
                setError(true);
              });
          } else {
            setApplying(false);
            setApplySuccess(true);

            return true;
          }
        });
      });
    }
  }

  function handleFileChange(e) {
    setFile(e ? e.target.files[0] : null);
  }

  const handleCheckbox = () => {
    setIsChecked((prevState) => !prevState);
  };

  return (
    <S.ApplicationSectionContainer>
      {!data ? (
        <LoadSpinner />
      ) : (
        <S.Container>
          <S.WelcomeText>
            Everyone of us has unique perspectives and experiences that enrich
            our team and help us thrive together. We value contributions from
            individuals of all backgrounds, so if the prospect of joining us
            excites you, we wholeheartedly encourage you to submit your
            application!
          </S.WelcomeText>
          {data && (
            <S.FormContainer>
              {!applySuccess ? (
                <S.FormInnerContainer>
                  <S.FormInputContainer $colSpan={3}>
                    <S.FormInput
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                      disabled={applying}
                    />
                  </S.FormInputContainer>
                  <S.FormInputContainer $colSpan={3}>
                    <S.FormInput
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                      disabled={applying}
                    />
                  </S.FormInputContainer>
                  <S.FormInputContainer $colSpan={4}>
                    <S.FormInput
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      disabled={applying}
                    />
                  </S.FormInputContainer>
                  <AttachButton
                    file={file}
                    setFile={setFile}
                    handleFileChange={handleFileChange}
                  />
                  <S.FormTextareaContainer>
                    <S.FormTextarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Feel free to add a message"
                    />
                  </S.FormTextareaContainer>
                </S.FormInnerContainer>
              ) : (
                <S.SuccessMessage>
                  We have received your application and will get back to you
                  shortly.
                  <br />
                  THANK YOU!
                </S.SuccessMessage>
              )}
              {!applySuccess && (
                <S.PrivacyPolicyContainer>
                  <S.CheckboxContainer>
                    <S.Checkbox
                      value={isChecked}
                      onClick={handleCheckbox}
                      $isChecked={isChecked}
                    >
                      {isChecked && (
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <S.CheckSvgPath d="M14.5306 5.03057L6.5306 13.0306C6.46092 13.1005 6.37813 13.156 6.28696 13.1938C6.1958 13.2317 6.09806 13.2512 5.99935 13.2512C5.90064 13.2512 5.8029 13.2317 5.71173 13.1938C5.62057 13.156 5.53778 13.1005 5.4681 13.0306L1.9681 9.53057C1.89833 9.4608 1.84299 9.37798 1.80524 9.28683C1.76748 9.19568 1.74805 9.09798 1.74805 8.99932C1.74805 8.90066 1.76748 8.80296 1.80524 8.71181C1.84299 8.62066 1.89833 8.53783 1.9681 8.46807C2.03786 8.3983 2.12069 8.34296 2.21184 8.30521C2.30299 8.26745 2.40069 8.24802 2.49935 8.24802C2.59801 8.24802 2.69571 8.26745 2.78686 8.30521C2.87801 8.34296 2.96083 8.3983 3.0306 8.46807L5.99997 11.4374L13.4693 3.96932C13.6102 3.82842 13.8013 3.74927 14.0006 3.74927C14.1999 3.74927 14.391 3.82842 14.5318 3.96932C14.6727 4.11021 14.7519 4.30131 14.7519 4.50057C14.7519 4.69983 14.6727 4.89092 14.5318 5.03182L14.5306 5.03057Z" />
                        </svg>
                      )}
                    </S.Checkbox>
                  </S.CheckboxContainer>
                  <S.PrivacyPolicyText>
                    I have read the{" "}
                    <NavLink to="/privacy" style={{ color: "#5B28B5" }}>
                      Privacy Policy
                    </NavLink>{" "}
                    and confirm that Modoyo store my personal details to be able
                    to process my job application.
                  </S.PrivacyPolicyText>
                </S.PrivacyPolicyContainer>
              )}
              {!applySuccess && (
                <S.ButtonContainer>
                  {!applying && !error && (
                    <CTA
                      callback={() => {
                        apply();
                      }}
                      disabled={!inputValid}
                    >
                      APPLY FOR THIS JOB
                    </CTA>
                  )}
                  {error && (
                    <h3
                      style={{
                        color: "red",
                        fontFamily: "Roboto Flex, sans-serif",
                      }}
                    >
                      Oh noes! We're so sorry but something has epicly failed..
                      :´(
                      <br />
                      Please send us a mail instead to{" "}
                      <a href="mailto:hello@modoyo.com">hello@modoyo.com</a>
                    </h3>
                  )}
                </S.ButtonContainer>
              )}
            </S.FormContainer>
          )}
        </S.Container>
      )}
    </S.ApplicationSectionContainer>
  );
}
