import React, { useState, useLayoutEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Theme } from "../../../utils/Theme";
import LoadSpinner from "../../../components/LoadSpinner";
// import {TimelineMax, Power2} from "gsap";
import * as S from "./style";
import * as GS from "../../../styles/global-style";
import Breadcrumb from "../../Breadcrumb";
import sword from "../../../imgs/sword.png";
import swordland from "../../../imgs/swordland.png";
import helmet from "../../../imgs/helmet.png";
import diamond from "../../../imgs/diamond.png";
import outlinering from "../../../imgs/outlinering.png";
import shroom from "../../../imgs/shroom.png";
import shield from "../../../imgs/shield.png";
import swordSvg from "../../../imgs/careers/sword.svg";
import swordSecondSvg from "../../../imgs/careers/sword-second.svg";
import ApplicationSection from "../ApplicationSection";
import VacanciesSection from "../../CareersPage/VacanciesSection";

window.Buffer = window.Buffer || require("buffer").Buffer;
var awsConfig = {};

export default function ContentSection() {
  const { setColor } = useContext(Theme);
  const { id } = useParams();

  const swordRef = useRef();
  const swordlandRef = useRef();
  const helmetRef = useRef();
  const diamondRef = useRef();
  const outlineringRef = useRef();
  const shroomRef = useRef();
  const shieldRef = useRef();

  const [data, setData] = useState(null);
  const icons = [
    swordland,
    sword,
    helmet,
    diamond,
    outlinering,
    shroom,
    shield,
  ];
  const refs = [
    swordlandRef,
    swordRef,
    helmetRef,
    diamondRef,
    outlineringRef,
    shroomRef,
    shieldRef,
  ];
  var iconIdx = 0;
  var switchSection = false;
  var continueBenefits = false;

  useLayoutEffect(() => {
    setColor("green");
    document.body.style = "background: #EDEDED;";

    fetch("/.netlify/functions/getaws", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((creds) => {
        awsConfig = creds;
      });

    if (id) {
      fetch("/.netlify/functions/getjob", {
        method: "POST",
        body: JSON.stringify({ id: id }),
      }).then(async (res) => {
        setData(parseData(await res.json()));
      });
    }
  }, [id, setColor]);

  function parseData(_data) {
    const _body = _data.data.attributes.body;
    const _arr = _body.split(">");
    var _arr2 = [];

    var type = "string";
    for (var i = 0; i < _arr.length; i++) {
      const str = _arr[i].trim();
      if (str.search("<ul") > -1) {
        type = "list";
      } else if (
        str.search("</ul") > -1 ||
        str.search("</h3") > -1 ||
        str.search("</h4") > -1 ||
        str.search("</em") > -1
      ) {
        type = "string";
      } else if (str.search("<h3") > -1) {
        type = "header";
      } else if (str.search("<h4") > -1) {
        type = "subheader";
      } else if (str.search("<em") > -1) {
        type = "bold";
      } else if (str.charAt(0) !== "<") {
        _arr2.push({ type: type, value: _arr[i].split("<")[0].trim() });
      }
    }

    return { title: _data.data.attributes.title, body: _arr2 };
  }

  return (
    <GS.HeaderFooterSpace $disabledHeaderSpace>
      {!data ? (
        <LoadSpinner />
      ) : (
        <S.ContentSectionContainer>
          <S.StyledSvg
            width="824"
            height="1213"
            viewBox="0 0 824 1213"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M569.156 -9.84116C483.355 -98.8591 375.455 -168.414 248.378 -217.55C137.227 -260.304 24.7752 -282 -85.4011 -282C-98.7262 -282 -112.376 -282 -125.701 -281.043C-249.203 -275.3 -366.854 -246.903 -475.731 -196.173C-583.632 -146.08 -681.133 -77.482 -765.309 8.34533C-848.835 93.8536 -913.836 194.039 -958.686 306.667C-1002.56 417.062 -1024.99 532.243 -1024.99 649.339C-1025.64 766.753 -1003.86 882.891 -960.636 994.243C-917.085 1105.6 -851.76 1206.74 -767.259 1294.8C-682.108 1383.5 -575.181 1453.05 -448.755 1501.55C-337.604 1544.3 -226.128 1566 -117.251 1566C-102.626 1566 -88.0007 1565.68 -73.3756 1564.72C50.1259 1557.7 167.778 1528.67 276.979 1478.58C386.18 1428.8 483.681 1359.89 566.557 1274.06C649.108 1188.55 713.784 1088.05 758.634 975.419C800.885 869.171 822.66 755.586 822.985 637.533C823.635 520.119 802.509 403.662 760.259 291.99C717.683 180.319 653.333 78.8577 568.507 -9.84116H569.156ZM241.878 985.31C86.5265 1134.31 -113.351 1223.01 -293.078 1223.01C-413.329 1223.01 -515.705 1184.4 -588.831 1111.02C-679.832 1019.45 -715.258 884.805 -687.958 731.975C-660.658 577.869 -573.231 424.082 -442.579 298.372C-287.553 149.37 -87.6758 60.6713 92.0514 60.6713C212.628 60.6713 314.679 99.5967 387.805 172.981C576.632 362.503 510.982 726.87 241.553 985.31H241.878Z"
              stroke="#FCFAFF"
              strokeWidth="2"
            />
          </S.StyledSvg>
          <Breadcrumb href="/careers" title="Careers" />
          <S.Container $xl>
            <S.ContentSectionWrapper>
              <S.DetailsContainer>
                <div>
                  {data &&
                    data.body &&
                    data.body.map((val, i) => {
                      if (switchSection) return <div key={"section1_0_" + i} />;
                      var str = "";
                      if (val.type === "header") {
                        if (val.value === "Benefits") {
                          switchSection = true;
                          return <div key={"section1_0_" + i} />;
                        }
                        str = (
                          <S.SubTitleContainer>
                            <img
                              ref={refs[iconIdx]}
                              alt="icon"
                              loading="lazy"
                              src={swordSecondSvg}
                              style={{
                                width: 46,
                                height: 46,
                              }}
                            />
                            <S.SubTitle>{val.value}</S.SubTitle>
                          </S.SubTitleContainer>
                        );
                        iconIdx++;
                      } else if (val.type === "subheader")
                        str = (
                          <h5 style={{ fontFamily: "Figtree, sans-serif" }}>
                            {val.value}
                          </h5>
                        );
                      else if (val.type === "string")
                        str = <S.Description>{val.value}</S.Description>;
                      else if (val.type === "list")
                        str = (
                          <div className="jobBullet" style={{ color: "black" }}>
                            <ul style={{ listStyleType: "disc" }}>
                              <li>
                                <S.Description>{val.value}</S.Description>
                              </li>
                            </ul>
                          </div>
                        );

                      return str ? (
                        val.type === "header" ? (
                          <div
                            key={"section1_" + i}
                            style={{ marginBottom: 20 }}
                          >
                            {str}
                          </div>
                        ) : (
                          <S.Description key={"section1_" + i}>
                            {str}
                          </S.Description>
                        )
                      ) : (
                        <div key={"section1_1_" + i} />
                      );
                    })}
                </div>
                <S.BenefitsContainer>
                  {data &&
                    data.body &&
                    data.body.map((val, i) => {
                      if (val.type === "header") {
                        if (val.value === "Benefits") {
                          continueBenefits = true;
                        }
                      }
                      if (!continueBenefits)
                        return <div key={"section2_0_" + i} />;

                      var str = "";
                      if (val.type === "header") {
                        if (val.value === "Benefits") {
                          continueBenefits = true;
                        }
                        str = (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {icons[iconIdx] && (
                              <img
                                alt="icon"
                                loading="lazy"
                                src={swordSvg}
                                style={{
                                  width: 46,
                                  height: 46,
                                  marginRight: 20,
                                }}
                              />
                            )}
                            <S.SubTitle $color="white">{val.value}</S.SubTitle>
                          </div>
                        );
                        if (icons[iconIdx]) iconIdx++;
                      } else if (val.type === "subheader")
                        str = <S.Description>{val.value}</S.Description>;
                      else if (val.type === "string")
                        str = (
                          <h3
                            className="font-neue-haas"
                            style={{ marginLeft: 66 }}
                          >
                            {val.value}
                          </h3>
                        );
                      else if (val.type === "list")
                        str = (
                          <div
                            className="jobBullet"
                            style={{
                              color: "black",
                            }}
                          >
                            <ul>
                              <li style={{ color: "white" }}>
                                <S.Description $color="white">
                                  {val.value}
                                </S.Description>
                              </li>
                            </ul>
                          </div>
                        );

                      return str ? (
                        <div key={"section2" + i}>{str}</div>
                      ) : (
                        <div key={"section2_1_" + i} />
                      );
                    })}
                </S.BenefitsContainer>
              </S.DetailsContainer>
            </S.ContentSectionWrapper>
          </S.Container>
        </S.ContentSectionContainer>
      )}
      <ApplicationSection id={id} data={data} awsConfig={awsConfig} />
      <S.VacanciesSectionContainer>
        <VacanciesSection />
      </S.VacanciesSectionContainer>
    </GS.HeaderFooterSpace>
  );
}
